import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchGroupData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/medicines/groups/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addGroupData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/medicines/groups/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateGroupData(ctx,queryParams){
            var data = queryParams;
            console.log(data)
            return new Promise((resolve, reject) => {
                axios
                  .put(`/medicines/groups/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteGroupData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/medicines/groups/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
    }
}